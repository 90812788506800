import React from "react";
import _ from "lodash";
import { Trans } from "@lingui/macro";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizedLink, useLocalization } from "gatsby-theme-i18n";
import Reaptcha from "reaptcha";
import parse from "html-react-parser";
import Container from "../components/Container";
import NewLayout from "../components/NewLayout";
import contact_image from "../images/contact-company.png";
import BoxDetails from "../components/BoxDetails";
import InterestedItems from "../components/InterestedItems";
import { officeKinds } from "../utils/common";
import Checkbox from "../components/Checkbox";
import { headers, restHeaders } from "../utils/navbar";
import { countries } from "../utils/countries";
import Mail from "../services/MailService";
import LoadingButton from "../components/LoadingButton";
import logo from "../images/logo-negative.png";
import Modal from "../components/Modal";

const optionsRadio = () => [
  { value: "I’m a Fund House", label: <Trans>I’m a Fund House</Trans> },
  { value: "I’m a Distributor", label: <Trans>I’m a Distributor</Trans> },
  { value: "Other", label: <Trans>Other</Trans> },
];

const inputs = () => [
  { value: "name", label: <Trans>Full name</Trans> },
  { value: "email", label: <Trans>Email</Trans> },
  { value: "company", label: <Trans>Company</Trans> },
  { value: "phone", label: <Trans>Phone number</Trans> },
  { value: "contact_text", label: <Trans>Message</Trans> },
];

function ContactPage() {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [isSent, setIsSent] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const { locale } = useLocalization();
  const messageInput = _.last(inputs());
  const interestedItems = [
    { ..._.get(headers(), "investors.elements.investors_about") },
    { ..._.get(restHeaders(), "about.elements.about_team") },
    { ..._.get(restHeaders(), "about.elements.about_history") },
  ];

  const sendContact = () => {
    setLoading(true);

    const contactData = {
      ...data,
      to: data?.email,
      request: "Website",
      title: "Contact",
      subtitle: "Website",
      subject: "Website",
      region: data?.country,
      contactText: data?.[messageInput?.value],
      clientType: data?.client,
    };

    Mail.sendContactRequest(contactData)
      .then(({ status }) => {
        if (status === 200) {
          setIsSent(true);
          setLoading(false);
          setData({});
          setOpenModal(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        // eslint-disable-next-line no-console
        console.error("Error al enviar el contacto:", error);
      });
  };

  return (
    <NewLayout byDefault apps="contact">
      {!isSent ? (
        <Container pb={8}>
          <Typography mt={3} mb={3} variant="h2">
            <Trans>Contact</Trans>
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h3">
                <Trans>Hello, how can we help you?</Trans>
              </Typography>
              <Typography mt={2} fontSize={16}>
                <Trans>
                  Please send us your question or suggestion and we will get back
                  to you as soon as possible.
                </Trans>
              </Typography>
              <Stack spacing={2} mt={2}>
                {inputs()
                  .slice(0, inputs().length - 1)
                  .map(({ value, label }) => (
                    <TextField
                      value={data[value] || ""}
                      label={label}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => setData({ ...data, [value]: e.target.value })}
                      required
                      disabled={loading}
                    />
                  ))}
                <Autocomplete
                  options={countries()}
                  renderInput={(params) => (
                    <TextField {...params} label={<Trans>Country</Trans>} />
                  )}
                  onChange={(_, item) => setData({ ...data, country: item.value })}
                  disabled={loading}
                />
                <Stack
                  spacing={{ xs: 1, sm: 2 }}
                  direction={{ xs: "column", sm: "row" }}
                >
                  {optionsRadio().map(({ label, value }) => (
                    <Checkbox
                      checked={value === data.client}
                      onChange={() => setData({ ...data, client: value })}
                    >
                      <Typography variant="small">{label}</Typography>
                    </Checkbox>
                  ))}
                </Stack>
                <TextField
                  value={data[messageInput.value] || ""}
                  label={messageInput.label}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setData({ ...data, [messageInput.value]: e.target.value })}
                  multiline
                  maxRows={10}
                  required
                  disabled={loading}
                />
                <Stack spacing={1} sx={{ fontStyle: "italic" }}>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={data.checkbox_terms}
                      onChange={() => setData({ ...data, checkbox_terms: !data?.checkbox_terms })}
                    >
                      <Trans>
                        I have read and accepted Allfunds general terms of use within the <LocalizedLink to="/legal-advice/legal-notice">Legal Notice</LocalizedLink> section.
                      </Trans>
                    </Checkbox>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={data.checkbox_policy}
                      onChange={() => setData({ ...data, checkbox_policy: !data?.checkbox_policy })}
                    >
                      <Trans>
                        I have read and accepted <LocalizedLink to="/legal-advice/privacy-policy">Allfunds Privacy Policy.</LocalizedLink>
                      </Trans>
                    </Checkbox>
                  </Box>
                </Stack>
                <Reaptcha
                  hl={locale}
                  sitekey={process.env.GATSBY_RECAPTCHA_KEY}
                  onVerify={(token) => setData({ ...data, "g-recaptcha-response": token })}
                />
                <Box>
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    disabled={
                      !data.checkbox_terms
                      || !data.checkbox_policy
                      || !data["g-recaptcha-response"]
                      || !Mail.validate(inputs(), data)
                    }
                    onClick={() => sendContact()}
                    loading={loading}
                  >
                    <Typography>
                      <Trans>Send</Trans>
                    </Typography>
                  </LoadingButton>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6} justifyContent="end" display="flex">
              <Box
                sx={{
                  height: { xs: "480px", sm: "680px" },
                  width: "580px",
                  display: "block",
                  backgroundSize: "cover",
                  backgroundImage: `url(${contact_image})`,
                  position: "relative",
                  backgroundPosition: "center",
                  borderRadius: "5px",
                  boxShadow: "25px 25px 0 0 #D50032",
                }}
              />
            </Grid>
          </Grid>
          <Box mt={10}>
            <Typography variant="h3">
              <Trans>Our Offices</Trans>
            </Typography>
            <Box mt={3}>
              <Stack mt={3} spacing={2}>
                {officeKinds(locale).map(({ kind, offices }) => (
                  <BoxDetails title={kind}>
                    <Grid container spacing={2}>
                      {offices.map(({ attributes: { city, address } }) => (
                        <Grid item xs={12} sm={6} md={3}>
                          <Typography variant="h5">{city}</Typography>
                          <Typography sx={{ "*": { margin: 0 } }}>
                            {parse(address)}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </BoxDetails>
                ))}
              </Stack>
            </Box>
          </Box>
          <InterestedItems items={interestedItems} />
        </Container>
      ) : (
        <Modal onClose={() => { setOpenModal(false); setIsSent(false); }} open={openModal} backgroundColor="white" width={550}>
          <Box textAlign="center">
            <img alt="logo" src={logo} style={{ width: "200px" }} />
            <Typography mt={2} variant="h3">
              <Trans>Thanks for your interest!</Trans>
            </Typography>
            <Typography m={2} variant="h4" color="rgba(14, 35, 64, 0.6)" sx={{fontWeight: "400"}}>
              <Trans>Your message was successfully sent!</Trans>
            </Typography>
            <Button
              size="large"
              color="secondary"
              variant="contained"
              sx={{
                    height: "50px",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
              onClick={() => setOpenModal(false)}
            >
              <Typography
                variant="info"
                sx={{
                      display: "flex",
                      alignItems: "center",
                      color: "white !important",
                      fontWeight: 600,
                    }}
                  >
                <Trans>Understood</Trans>
              </Typography>
            </Button>
          </Box>
        </Modal>
      )}
    </NewLayout>
  );
}

export default ContactPage;
